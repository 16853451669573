<template>
  <div class="shop_main">
    <div class="shop_search_menu">
      <a href="javascript:void(0)" @click="$router.replace('/index')">首页</a>
      <i class="el-icon-arrow-right"></i>
      <a href="javascript:void(0)">三方质检</a>
    </div>

    <img :src="'./img/banner_quality_guolian@3x.png'" class="page_banner" />
    <div class="shop_quality">
      <div class="shop_quality_title">
        <img class="guolian_title_img1" :src="'./img/guolian_title1.png'" alt="">
      </div>

      <div class="shop_quality_intro">
        <p>
          西安国联质量检测技术股份有限公司——中国综合性第三方检测认证机构引领者，提供一站式的检验检测、认证、计量、安评，技术咨询等服务。公司成立于2011年，总部位于古都西安，在全国拥有超过20000㎡实验室，近1000名专业技术人员。经过10年发展，国联质检的服务能力已经覆盖42个领域、121个大类、6111个参数，合作客户超过10万，累计出具报告20万余份。
        </p>
      </div>

      <div class="intro_img_groups">
        <div class="item item_bg1" :style="{'background-image': 'url(./img/guolian_img02.png)'}">
          <img class="icon" :src="'./img/guolian_img01.png'" alt="">
          <p class="mt20 pl54">实验室：20000㎡+</p>
          <p class="pl54">专业团队：1000人+</p>
          <p class="pl54">服务客户：100000+</p>
        </div>
        <div class="item item_bg2" :style="{'background-image': 'url(./img/guolian_img04.png)'}">
          <img class="icon" :src="'./img/guolian_img03.png'" alt="">
          <p class="mt20 pl68">服务领域：42个</p>
          <p class="pl68">服务大类：121个</p>
          <p class="pl68">服务参数：6111个</p>
        </div>
        <div class="item item_bg3" :style="{'background-image': 'url(./img/guolian_img06.png)'}">
          <img class="icon" :src="'./img/guolian_img05.png'" alt="">
          <p class="mt20 pl30">先进仪器：2800台+</p>
          <p class="pl30">出具报告：200000份+</p>
          <p class="pl30">业务范围：全国市县800+</p>
        </div>
      </div>

      <div class="shop_quality_title">
        <img class="guolian_title_img2" :src="'./img/guolian_title2.png'" alt="">
      </div>

      <div class="shop_quality_certificate">
        <img class="mt20" :src="'./img/guolian_img07.png'" alt="">
        <img :src="'./img/guolian_img08.png'" alt="">
        <img class="mt20" :src="'./img/guolian_img09.png'" alt="">
      </div>

      <div class="shop_quality_title">
        <img class="title_img4" :src="'./img/guolian_title3.png'" alt="">
      </div>

      <div class="shop_quality_example shop_quality_example_guolian" :style="{'background-image': 'url(./img/guolian_img24.png)'}">
        <div class="example borderBottom">
          <img class="mt11" :src="'./img/guolian_img10.png'" alt="">
          <p>中药材</p>
        </div>
        <div class="example borderBottom">
          <img class="mt11" :src="'./img/guolian_img11.png'" alt="">
          <p>农副产品</p>
        </div>
        <div class="example borderBottom">
          <img class="mt11" :src="'./img/guolian_img12.png'" alt="">
          <p>金属</p>
        </div>
        <div class="example borderBottom borderRight">
          <img class="mt11" :src="'./img/guolian_img13.png'" alt="">
          <p>药品</p>
        </div>
        <div class="example">
          <img class="mt35" :src="'./img/guolian_img14.png'" alt="">
          <p>食品</p>
        </div>
        <div class="example">
          <img class="mt35" :src="'./img/guolian_img15.png'" alt="">
          <p>化工品</p>
        </div>
        <div class="example">
          <img class="mt35" :src="'./img/guolian_img16.png'" alt="">
          <p>油漆涂料</p>
        </div>
        <div class="example borderRight">
          <img class="mt35" :src="'./img/guolian_img17.png'" alt="">
          <p>其他</p>
        </div>
      </div>

      <div class="shop_quality_title">
        <img class="guolian_title_img3" :src="'./img/guolian_title4.png'" alt="">
      </div>

      <div class="shop_quality_environment">

        <div class="environment_contain">
          <p class="environment_text">国联质检目前拥有油品、化工、医药、食品、微生物、力学、中药材、分析、毒理功能、环境等八大实验室；实验室拥有ICP、GC-MS、GC、LC、LC-MS、红外光谱仪、微控摩擦实验仪、200T万能试验机、扭矩试验机、环境氨测量仪、磁性金属物测定仪等1200多台/套国际先进仪器。国联质检团队成员皆来自国内外知名院校和研究机构，核心人员平均从业经历在十年以上。与国内外600多家科研机构和高等院校的实验室开展跨区域的技术协作共享，检验结果得到全球74个国家公认。</p>
        </div>

        <div class="environment_img_group mt40">
          <img class="mr20" :src="'./img/guolian_img18.png'" alt="">
          <img :src="'./img/guolian_img19.png'" alt="">
          <img class="mr20 mt20" :src="'./img/guolian_img20.png'" alt="">
          <img class="mt20" :src="'./img/guolian_img21.png'" alt="">
          <img class="mr20 mt20" :src="'./img/guolian_img22.png'" alt="">
          <img class="mt20" :src="'./img/guolian_img23.png'" alt="">
        </div>
      </div>

      <Contract :type="2"></Contract>
    </div>
  </div>
</template>

<script>
const Contract = () => import('./contract');
export default {
  name: "guoLian",
  created() {
    this.$store.commit('changeScrollTop');
  },
  components: {
    Contract
  }
}
</script>

<style scoped>

</style>
